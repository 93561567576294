
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { mixins } from 'vue-class-component';
import { Environments, LlmIgnoreChars, RouteNames, Statuses } from '@/constants';
import { groupBy, isEmpty } from 'lodash';
import * as API from '@/api/projects.api';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import ContentCard from '@/components/ContentCard.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import StatusIconComponent from '@/components/StatusIconComponent.vue';
import { ClickData } from '@/analytics';
import FeatureFlag from '@/components/FeatureFlag';
import GAnalytics from '@/components/GAnalytics';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import HealthCheck from '@/components/HealthCheck.vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import ProjectEnvMetaMixin from '@/components/mixins/project-env-meta.mixin';
import { EnumsModule } from '@/store/modules/enums.module';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { ProjectEnvironmentConnectedService } from '@/types/projects.types';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { removeQuery } from '@/utils/routing';
import UserList from '@/components/UserList.vue';
import CopyCode from '@/components/CopyCode.vue';

 @Component({
  components: {
    ContentCard,
    GButton,
    DeleteDialog,
    GAnalytics,
    HelpTooltip,
    HealthCheck,
    FeatureFlag,
    GDialog,
    UserList,
    CopyCode,
    StatusIconComponent,
  },
})
export default class ProjectEnvServices extends mixins(ProjectEnvMetaMixin) {
  @Prop({ type: Array, required: true })
  connectedServices!: DeepReadonly<API.Projects.ProjectEnvironmentConnectedService[]>;
  @Prop({ type: Object, required: true })
  environment!: DeepReadonly<API.Projects.ProjectEnvironment>;
  @Prop({ type: Array, required: true })
  registrations!: DeepReadonly<API.Projects.ProjectService[][]>;
  @Prop({ type: String, required: true })
  environmentName!: Environments;
  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ type: Number, required: true }) projectEnvId!: number;
  public toggles : any= {}

  public openUnavailableDialog = false;
  public healthCheckKey = 0;

  private successOpen = false;
  created() {
    const s = 'success';
    if (s in this.$route.query) {
      this.successOpen = true;
      removeQuery(this, [s]);
    }
  }

  get isDelayedProcessingInfo(): string {
    return ProcessEnvsModule.processEnvs.delayedProcessingInfo;
  }

  actualServiceName(s: string) {
    return !!s && s.substring(0, s.lastIndexOf(' '))
  }

  actualVersion(s: string) {
    return !!s && s.substring(s.lastIndexOf(' ') + 1, s.length);
  }

  get groupedConnectedServices() {
    return groupBy(this.connectedServices, s => this.actualServiceName(s.serviceName));
  }

  removeTimeout = -1;

  get connectAnalytics(): ClickData {
    return {
      clickTarget: 'project-details-service-connect-button',
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
    };
  }

  get canEditProject(): boolean {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    return EnumsModule.enums.role.OWNER.id === currentUserRoleId;
  }

  get showConnectButton(): boolean {
    return this.environmentName === Environments.Dev && this.canEditProject;
  }

  get envId() {
    return this.environment?.environmentId;
  }

  clickAnalytics(
    name: string,
    service: API.Projects.ProjectEnvironmentConnectedService,
  ): ClickData {
    return {
      clickTarget: name,
      serviceName: service.serviceName,
      serviceId: service.serviceId,
      projectEnvironment: this.environmentName,
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
    };
  }

  tryAgain() {
    const { projectEnvId, projectId } = this;
    ProjectDetailsModule.getProjectEnvironmentPart({
      projectId,
      projectEnvId,
      partName: 'services',
    });
  }

  refreshConnectedServices(){
    this.healthCheckKey += 1;
    this.$emit('reload');
  }

  isHealthCheckEnabled(healthCheckPath: string, healthCheckPattern: string) {
    return !(isEmpty(healthCheckPath) || isEmpty(healthCheckPattern));
  }

  disableTrash(service: API.Projects.ProjectEnvironmentConnectedService) {
    if (service.statusName === Statuses.Provisioning || service.statusName === Statuses.Deleting) {
      return true;
    }

    const projectId = ProjectDetailsModule.projectDetails.projectId;
    const projectEnvId = service.projectEnvironmentId;
    const serviceEnvId = service.serviceId;
    const vueSetKey = `${projectId}-${projectEnvId}-${serviceEnvId}`;
    if (this.removingService[vueSetKey]) {
      return true;
    }

    return this.registrations
      .flatMap(regs => regs.map(reg => reg.registrationVersionId))
      .includes(service.registrationVersionId);
  }

  private loading = false;
  private removingService: Record<string | number, boolean> = Object.create(null);
  async removeService(service: API.Projects.ProjectEnvironmentConnectedService) {
    this.loading = true;
    const projectId = ProjectDetailsModule.projectDetails.projectId;
    const projectEnvId = service.projectEnvironmentId;
    const serviceEnvId = service.serviceId;
    const vueSetKey = `${projectId}-${projectEnvId}-${serviceEnvId}`;
    Vue.set(this.removingService, vueSetKey, true);

    await ProjectDetailsModule.removeConnectedService({
      projectId,
      projectEnvId,
      serviceEnvId,
      connectedServices: this.connectedServices as ProjectEnvironmentConnectedService[],
    })
      .then(() => {
        openSnackbar.call(this, 'Connected Service Successfully Deleted');
        this.$emit('delete');
      })
      .catch(() => {
        openErrorSnackbar.call(this, 'Error: Failed to delete Service');
      })
      .finally(() => {
        this.open = false;
        this.loading = false;
      });
    this.removeTimeout = setTimeout(() => {
      this.tryAgain();
      Vue.delete(this.removingService, vueSetKey);
    }, 1000);
  }

  // map of reg id to row group open state
  rowState: Record<string, boolean | undefined> = {};
  toggleTable(listingId: number) {
    Vue.set(this.rowState, listingId, !this.rowState[listingId]);
  }
  showRow(index: number, listingId: number) {
    if (index > 0) {
      return this.rowState[listingId];
    }
    return true;
  }

  beforeDestroy() {
    clearTimeout(this.removeTimeout);
  }

  private open = false;

  selectedService: API.Projects.ProjectEnvironmentConnectedService = {
    authenticationType: '',
    environmentName: '',
    projectEnvironmentId: -1,
    serviceDescription: '',
    serviceEnvironmentId: -1,
    serviceId: -1,
    serviceName: '',
    statusId: -1,
    status: '',
    statusName: '',
    upstreamUrl: '',
    registrationId: -1,
    registrationVersionId: -1,
    wlCount:0
  };
  selectedServiceIndex = -1;

  public openDialog(service: API.Projects.ProjectEnvironmentConnectedService, index: number): void {
    this.selectedService = service;
    this.selectedServiceIndex = index;
    this.open = true;
  }

  goToListing(service: API.Projects.ProjectEnvironmentConnectedService) {
    const route = {
      name: RouteNames.ListingDetails,
      params: {
        listingId: service.listingId,
      },
      query: {},
    };
    if (FeatureFlagsModule.tryitEnabled) {
      route.query = {
        ...route.query,
        referrerProjectId: this.projectId,
        referrerProjectEnvId: service.projectEnvironmentId,
        referrerServiceId: service.serviceId,
      };
    }
    return route;
  }

  upstreamUrl(ref: ProjectEnvironmentConnectedService) {
    const url = (
      (ProcessEnvsModule.getApiGatewayUrlMapping.get(ref.environmentName.toUpperCase()) || '') +
      ref.upstreamUrl
    ).replaceAll(LlmIgnoreChars.TILDE, '').replaceAll(LlmIgnoreChars.AI_PATH, '');
    return url;
  }

  get hasConnectedServices() {
    return !!this.connectedServices.length;
  }

  get listingsLink() {
    return {
      name: RouteNames.ListingType,
      params: {
        type: 'api',
      },
    };
  }

  get kongDisabled(): boolean {
    return !FeatureFlagsModule.kongEnabled;
  }

  get registrationDisabled(): boolean {
    return !FeatureFlagsModule.registrationEnabled;
  }

  get maintenanceModeDev(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledDev;
  }
  get maintenanceModeQA(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledQA;
  }
  get maintenanceModeProd(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledProd;
  }
  get maintenanceModeEnabled(): boolean {
    if((this.environmentName === Environments.Dev) && (this.maintenanceModeDev || this.maintenanceModeProd)){
      return true;
    }
    if((this.environmentName === Environments.Qa) && (this.maintenanceModeQA || this.maintenanceModeProd)){
      return true;
    }
    if((this.environmentName === Environments.Prod) && this.maintenanceModeProd){
      return true;
    }
    return false;
  }
  get maintenanceModeMessage() {
    return ProcessEnvsModule.processEnvs.maintenanceModeMessage;
  }
  showUnavailableDialog(): void {
    // show dialog
    this.openUnavailableDialog = true;
  }

  async checkAndPopulateConnectedServicesIfNeeded() {
    const { data: needsUpdate } = await API.populateConnectedServices({
      envId: this.projectEnvId,
    });
    if (needsUpdate == 1) {
      this.$emit('reload');
    }
  }

  private reloadInterval = 0;
  private RELOAD_CALL_INTERVAL = 10 * 60 * 1000; // 10 mins
  mounted() {
    this.reloadInterval = setInterval(
      this.checkAndPopulateConnectedServicesIfNeeded,
      this.RELOAD_CALL_INTERVAL,
    );
    this.checkAndPopulateConnectedServicesIfNeeded(); // immediate call when user landed to connected services page
  }

  destroyed() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  toggleData(index :any) {
    Vue.set(this.toggles, index, !this.toggles[index]);
  }
}
