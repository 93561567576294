
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { mixins } from 'vue-class-component';
import dayjs from 'dayjs';
import { get, isArray, findIndex } from 'lodash';
import * as API from '@/api/projects.api';
import ContentCard from '@/components/ContentCard.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import { openSnackbar, sanitize } from '@/utils/components';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import { analyticsClick } from '@/analytics';
import HelpTooltip from '@/components/HelpTooltip.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import CopyCode from '@/components/CopyCode.vue';
import { Environments, AUTH_SRC } from '@/constants';
import ProjectEnvMetaMixin from '@/components/mixins/project-env-meta.mixin';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { secretKeyValPair, ApiKey } from '@/types/projects.types';
import { EnumsModule } from '@/store/modules/enums.module';
import GskTextfieldWithPlusBtn from '@/components/gsk-components/GskTextfieldWithPlusBtn.vue'
import  customParseFormat from 'dayjs/plugin/customParseFormat'
import  ApiKeysSecretsTable from './ApiKeysSecretsTable.vue'

dayjs.extend(customParseFormat)

@Component({
  components: {
    ContentCard,
    GButton,
    GTextfield,
    DeleteDialog,
    GDialog,
    HelpTooltip,
    CopyCode,
    GskTextfieldWithPlusBtn,
    ApiKeysSecretsTable
  },
})
export default class ProjectEnvAuth extends mixins(ProjectEnvMetaMixin) {
  @Prop({ type: Object, required: true })
  environmentDetails!: DeepReadonly<API.Projects.ProjectEnvironmentDetails2['auth']>;
  @Prop({ type: Object, required: true })
  environmentStatus!: DeepReadonly<API.Projects.ProjectEnvironmentDetails2['status']>;
  @Prop({ type: String, required: true }) environmentName!: Environments;
  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ type: Number, required: true }) projectEnvId!: number;

  selectedUrl = '';
  newSecretName = '';
  newApiKeyName = '';
  public clientSecret: secretKeyValPair|undefined;
  public apiKey: ApiKey | undefined;
  public showSecret = false;
  public showApiKey = false;
  public deleteSecret = false;
  public deleteApiKey = false;
  public showAddWarning = false;
  public showResetWarning = false;
  public verifyAddition = false;
  public verifyReset = false;
  open = false;
  federationOpen = false;
  checkValid = true;
  checkValidSecretName = true;
  checkValidApiKeyName = true;
  private _oAuthClientSecret: string | secretKeyValPair[] = '';
  public openDialog(url: string): void {
    this.selectedUrl = url;
    this.open = true;
  }
  public adOAuthClientSecretIndexes = [0]
  public sanitizeLink = (source: string) => sanitize(source);
  

  get isAzureAuthType() {
    return this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic;
  }

  get authCodeHelpLink(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return 'https://www.pingidentity.com/content/dam/developer/downloads/Resources/OAuth2%20Developers%20Guide%20(1).pdf';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return 'https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow';
    }

    return '';
  }

  get clientCredentialsHelpLink(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return 'https://www.pingidentity.com/content/dam/developer/downloads/Resources/OAuth2%20Developers%20Guide%20(1).pdf';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return 'https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-client-creds-grant-flow';
    }

    return '';
  }

  get isAuthApiLoading() {
    return ProjectDetailsModule.authDetailsLoading;
  }

  get isClientSecretDeleteLoading() {
    return ProjectDetailsModule.clientSecretDeleteLoading;
  }

  get isClientSecretResetLoading() {
    return ProjectDetailsModule.clientSecretResetLoading;
  }

  get isClientSecretSavingLoading() {
    return ProjectDetailsModule.clientSecretSavingLoading;
  }

  get isProduction() {
    return this.environmentName === Environments.Prod;
  }

  get isApiKeyAddLoading() {
    return ProjectDetailsModule.apiKeyAddLoading;
  }

  get isApiKeyDeleteLoading() {
    return ProjectDetailsModule.apiKeyDeleteLoading;
  }

  get canUserEditProject(): boolean {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    let isOwner = EnumsModule.enums.role.OWNER.id === currentUserRoleId;
    return !isOwner;
  }

  get airAccessPermissionLink() {
    return process.env?.VUE_APP_AIR_ACESS_PERMISSION_LINK || '';
  }

  showFederationDialog() {
    this.federationOpen = true;
  }

  get oAuthType(): string {
    return this.environmentDetails.OAuth.oauthType;
  }

  get authBaseUrl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return ProcessEnvsModule.processEnvs.federationURL[this.environmentName];
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      const tenantId = ProcessEnvsModule.processEnvs.azureADTenantId;
      return `https://login.microsoftonline.com/${tenantId}`;
    }

    return '';
  }

  get authUrl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return this.authBaseUrl + '/as/authorization.oauth2';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return this.authBaseUrl + '/oauth2/v2.0/authorize';
    }
    return '';
  }

  get authCode(): string {
    return `${this.authUrl}?response_type=code&client_id=${this.environmentDetails.OAuth.clientId}&redirect_uri=${this.callbackUrlEncoded}`;
  }

  get authCodeCurl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return `curl -X POST ${this.tokenUrl} -d 'client_id=${this.environmentDetails.OAuth.clientId}&client_secret=${this.environmentDetails.OAuth.secret}&grant_type=authorization_code&code={{authorization_code}}&redirect_uri=${this.callbackUrlEncoded}'`;
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      const secret = Array.isArray(this.environmentDetails.OAuth.secret)  ? (this.environmentDetails.OAuth.secret as secretKeyValPair[])[0]?.value : this.environmentDetails.OAuth.secret;
      return `curl -X POST ${this.tokenUrl} -d 'client_id=${this.environmentDetails.OAuth.clientId}&client_secret=${secret}&grant_type=authorization_code&code={{authorization_code}}&redirect_uri=${this.callbackUrlEncoded}&scope=.default'`;
    }
    return '';
  }

  get tokenUrl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return this.authBaseUrl + '/as/token.oauth2';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return this.authBaseUrl + '/oauth2/v2.0/token';
    }
    return '';
  }

  get logoutUrl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return this.authBaseUrl + '/oidc/token/revocation';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return this.authBaseUrl + '/oauth2/v2.0/logout';
    }
    return '';
  }

  get userInfoUrl(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return this.authBaseUrl + '/idp/userinfo.openid';
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      return 'https://graph.microsoft.com/oidc/userinfo';
    }
    return '';
  }

  get clientCredentials(): string {
    if (this.oAuthType === AUTH_SRC.PING_OAUTH.mnemonic) {
      return `curl -X POST ${this.tokenUrl} -d 'client_id=${this.environmentDetails.OAuth.clientId}&client_secret=${this.environmentDetails.OAuth.secret}&grant_type=client_credentials'`;
    } else if (this.oAuthType === AUTH_SRC.AD_OAUTH.mnemonic) {
      const secret = Array.isArray(this.environmentDetails.OAuth.secret)  ? (this.environmentDetails.OAuth.secret as secretKeyValPair[])[0]?.value : this.environmentDetails.OAuth.secret;
      return `curl -X POST ${this.tokenUrl} -d 'client_id=${this.environmentDetails.OAuth.clientId}&client_secret=${secret}&grant_type=client_credentials&scope=${this.environmentDetails.OAuth.clientId}/.default'`;
    }
    return '';
  }

  get callbackUrlEncoded(): string {
    const { callbackUrls } = this.environmentDetails.OAuth;
    return callbackUrls?.length && callbackUrls.length > 0
      ? encodeURI(callbackUrls[callbackUrls.length - 1])
      : '';
  }

  get oAuthClientId() {
    return this.environmentDetails?.OAuth?.clientId
  }

  get oAuthClientSecret(): string | secretKeyValPair[] {
    if(this.environmentDetails.OAuth && 
    this.environmentDetails.OAuth.secret && 
    this.environmentDetails.OAuth.secret?.length > 0)  {
      this._oAuthClientSecret = (this.environmentDetails?.OAuth?.secret as unknown as secretKeyValPair[]);
    } else {
      this._oAuthClientSecret = this.environmentDetails?.OAuth?.secret as string;
    }
    return this._oAuthClientSecret;
  }

  set oAuthClientSecret(secret: string | secretKeyValPair[]) {
    this._oAuthClientSecret = secret;
  }

  get isSecretExists() {
    if(Array.isArray(this.environmentDetails.OAuth.secret)) {
      return (this.environmentDetails.OAuth.secret as unknown as secretKeyValPair[]).length > 0;
    } else if(typeof this.environmentDetails.OAuth.secret === 'string'){
      return !!this.environmentDetails.OAuth.secret;
    }
    return false;
  }
  
  disabledCallbackUrls: Record<string, boolean> = Object.create(null);
  callbackUrl = '';
  protected secretFields = {
    oauth: true,
    basic: true,
  };

  onSecretToggle(field: 'oauth' | 'basic'): void {
    analyticsClick({
      clickTarget: 'project-view-auth-secret',
      projectId: this.projectId,
      projectEnvironment: this.environmentName,
      projectName: ProjectDetailsModule.projectDetails.projectName,
      secretType: field,
    });
  }

  get callbackUrls() {
    return this.environmentDetails.OAuth.callbackUrls;
  }

  get callbackTooltipText(): string {
    if (this.isProduction) {
      return 'https:// is required for production environment';
    }
    return '';
  }

  get callbackValid(): boolean {
    if (this.callbackUrl?.trim() === '') {
      // just to simulate `dirty`
      return true;
    }
    return !this.callbackValidationMessage;
  }

  validateUrl(url: string): boolean
  {
    if(url == '')
    {
      return false;
    }
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !urlPattern.test(url);
  }

  get callbackValidationMessage(): string {
    // no dupes
    this.checkValid = true;
    if (this.callbackUrls?.includes(this.callbackUrl)) {
      return 'URL should not be a duplicate';
    }
    //url must be a valid url
    if(this.validateUrl(this.callbackUrl))
    {
      // invalid
      return 'This URL is not a valid URL.';
    }
    // prod must be https
    if (this.isProduction) {
      if (/https:\/\/.+/.test(this.callbackUrl)) {
        // valid
        return '';
      }
      return 'https:// URL is required for production';
    } else {
      // other must be http/https
      if (/https?:\/\/.+/.test(this.callbackUrl)) {
        // valid
        return '';
      }
      return 'URL should start with http:// or https://';
    }
  }

  get oAuthTitle(): string {
    if (this.oAuthType in AUTH_SRC) {
      return AUTH_SRC[this.oAuthType].readableName;
    }

    return '';
  }

  get clientSecrets(): API.Projects.clientSecret[] {
    return get(this.environmentDetails,'OAuth.ssoSetting.clientSecrets',[]);
  }

  get apiKeys(): API.Projects.ApiKey[] {
    return get(this.environmentDetails,'basic.apiKeys',[]);
  }

  isLoading(): boolean {
    return this.initialLoading ||  
    this.isAuthApiLoading || 
    this.isClientSecretDeleteLoading || 
    this.isClientSecretResetLoading || 
    this.isClientSecretSavingLoading ||
    this.isApiKeyAddLoading ||
    this.isApiKeyDeleteLoading;
  }

  loadingOverlayLabel(): string {
    let label = ''
    if (this.initialLoading) label ='Loading authentication details...'
    else if (this.isAuthApiLoading) label = 'Adding Client Secret...'
    else if (this.isClientSecretDeleteLoading) label = 'Deleting Client Secret...'
    else if (this.isClientSecretResetLoading) label = 'Resetting Client Secret...'
    else if (this.isClientSecretSavingLoading) label = 'Saving Client Secret...'
    else if (this.isApiKeyAddLoading) label = 'Generating new Api Key...'
    else if (this.isApiKeyDeleteLoading) label = 'Deleting API Key...';

    return label;
  }

  resetClientCredentials() {
    const payload = {
      envId: this.projectEnvId,
      ssoSettingId: this.environmentDetails.OAuth.ssoSetting.id,
    };
    this.confirmSecretReset();
    this.$emit('resetClientSecret', payload);
  }

  confirmSecretAddition() {
    this.verifyAddition = !this.verifyAddition;
  }

  confirmSecretReset() {
    this.verifyReset = !this.verifyReset;
  }

  setClientSecret(id: string) {
    const secret = this.environmentDetails.OAuth.secret;
    if(isArray(secret)) {
      this.clientSecret = secret.find((secret) => {
        return secret.key === id || null;
      });
    }
  }

  setApiKey(id: string) {
    const apiKeys = this.environmentDetails.basic.apiKeys;
    if (isArray(apiKeys)) {
      this.apiKey = apiKeys.find(apiKey => apiKey.id === id );
    }
  }

  showSecretVal(id: string, secretType?: string) {
    if (secretType === 'apiKey') {
      this.showApiKey = true;
      this.setApiKey(id)
    } else {
      this.setClientSecret(id);
    }
    this.showSecret = true;
  }

  getSecretDescription() {
    const id = this.clientSecret?.key;
    const secret = Array.isArray(this.clientSecrets) && this.clientSecrets.find((secret) => {
      return secret.id === id || null;
    });
    return secret && secret?.description;
  }

  getApiKeyName() {
    const id = this.apiKey?.id;
    const apiKey = Array.isArray(this.apiKeys) && this.apiKeys.find((apiKey) => {
      return apiKey.id === id || null;
    });
    return apiKey && apiKey?.name;
  }

  showDeleteDialog(id: string, mode: string) {
    if (mode === "apiKey") {
      this.deleteApiKey = true;
      this.setApiKey(id);
    } else {
      this.deleteSecret = true;
      this.setClientSecret(id)
    }
  }

  addNewSecret() {
    const description = this.newSecretName.trim();
    const payload = {
      envId: this.projectEnvId,
      ssoSettingId: this.environmentDetails.OAuth.ssoSetting.id,
      description,
    };
    this.confirmSecretAddition();
    this.$emit('addClientSecret', payload);
    this.newSecretName = '';
  }

  async deleteSecretFromTable() {

    const payload = {
      envId: this.projectEnvId,
      ssoSettingId: this.environmentDetails.OAuth.ssoSetting.id,
      clientSecretId: this.clientSecret?.key,
    };
    this.$emit('deleteClientSecret', payload)
  }

  async deleteApiKeyFromTable() {
    const payload = {
      envId: this.projectEnvId,
      apiKeyId: this.apiKey?.id
    };
    this.$emit('deleteApiKey', payload)
  }

  secretVal() {
    if (this.showApiKey) {
      return get(this.apiKey, 'value', '')
    } else {
      return get(this.clientSecret, 'value', '');
    }
  }

  async removeCallbackUrl(url: string): Promise<void> {
    this.$log('removing callback url:', url);
    Vue.set(this.disabledCallbackUrls, url, true);
    const urlSet = new Set(this.callbackUrls);
    urlSet.delete(url);
    await ProjectDetailsModule.updateCallbackUrls({
      projectId: Number(this.projectId),
      projectEnvId: Number(this.projectEnvId),
      urls: [...urlSet],
    });
    Vue.delete(this.disabledCallbackUrls, url);
    this.open = false;
  }

  validateSecretDescription(): boolean {
    this.checkValidSecretName = true;
    const textPattern = /^([a-zA-Z0-9 _-]+)$/;
    const newSecretDesc = this.newSecretName.trim();
    const isDuplicateIndex = findIndex(this.clientSecrets, ['description', newSecretDesc]);

    if(newSecretDesc.length === 0 || isDuplicateIndex !== -1 || !textPattern.test(this.newSecretName)
    || newSecretDesc.length <= 2 || newSecretDesc.length > 100) {
      this.checkValidSecretName = false;
      return false;
    } else {
      if(textPattern.test(this.newSecretName)) {
        return true;
      } else {
        this.checkValidSecretName = false;
        return false;
      }
    }
  }

  addSecretDescription(value: string) {
    this.newSecretName = value;
    if(this.validateSecretDescription()) {
      this.showAddWarning = true;
    }
  }

  async addCallbackUrl(): Promise<void> {
    if (this.callbackUrls.includes(this.callbackUrl)) {
      this.checkValid = false;
      return;
    }
    // prod must be https
    if (this.isProduction) {
      if (!/https:\/\/.+/.test(this.callbackUrl)) {
        // invalid
        this.checkValid = false;
      }
    } else {
      // other must be http/https
      if (!/https?:\/\/.+/.test(this.callbackUrl)) {
        // invalid
        this.checkValid = false;
      }
    }

    //url must be a valid url
    if(this.validateUrl(this.callbackUrl))
    {
      // invalid
      this.checkValid = false;
    }
    if (this.callbackUrl.trim() === '') {
      return;
    }
    if (!this.callbackValid) {
      return;
    }

    const url = this.callbackUrl;
    Vue.set(this.disabledCallbackUrls, url, true);
    if (this.checkValid)
      ProjectDetailsModule.updateCallbackUrls({
        projectId: Number(this.projectId),
        projectEnvId: Number(this.projectEnvId),
        urls: [...this.callbackUrls, url],
      })
        .then(() => {
          this.callbackUrl = '';
        })
        .catch(() => {
          openSnackbar.call(this, 'Error adding callback URL');
        })
        .finally(() => {
          Vue.delete(this.disabledCallbackUrls, url);
        });
  }

  async saveClientSecret() {
    const payload = {
      envId: this.projectEnvId,
      clientId: this.oAuthClientId,
      secrets: this._oAuthClientSecret
    };
    this.$emit('modifySecretCredential', payload);
    this._oAuthClientSecret = Array.isArray(this._oAuthClientSecret) ? [] : '';
  }

  
  addADClientSecret(key: string, value: string) {
    if(! (this._oAuthClientSecret as unknown as secretKeyValPair[])) {
      (this._oAuthClientSecret as unknown as secretKeyValPair[]) = [];
    }
    const secret = (this._oAuthClientSecret as unknown as secretKeyValPair[]).find( (s) => key === s.key );
    if(secret) {
      secret.value = value;
    } else {
      (this._oAuthClientSecret as unknown as secretKeyValPair[]).push({key, value});
    }
  }

  addApiKey (newValue: string): void { 
    const apiKeyName = newValue.trim();
    this.newApiKeyName = apiKeyName;
    const payload = {
      envId: this.projectEnvId,
      ssoSettingId: this.environmentDetails.OAuth.ssoSetting.id,
      name: apiKeyName,
    };
    this.$emit('addApiKey', payload);
    this.newApiKeyName = '';
  }

}
