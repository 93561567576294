
import { Component, Prop, Vue, Watch,} from 'vue-property-decorator';
import {registerables, Chart, ChartType, ChartEvent, LegendItem} from 'chart.js';
import 'chartjs-adapter-dayjs-3';
import PrometheusPlugin from './prometheus-plugin';
import { PrometheusReportType } from './prometheus-report-type.enum';

@Component({})
export default class GPrometheusAnalyticsChart extends Vue {
  @Prop() readonly type!: ChartType;
  @Prop() label!: string;
  @Prop() service!: string;
  @Prop() borderWidth!: number;
  @Prop() borderColor!: Record<string, string>[];
  @Prop() backgroundColor!: Record<string, string>[];
  @Prop() fill!:boolean;
  @Prop() title!: string;
  @Prop() reportType!: PrometheusReportType;
  @Prop() projectId!: number;
  @Prop() legendUsePointStyle!: boolean;
  @Prop() stepped!:boolean;
  @Prop() truncateLegend!: boolean;

  chart!: Chart;

  mounted() {
    Chart.register(...registerables, PrometheusPlugin);
      if(this.chart) {
          this.chart.destroy();
      }
      this.chart = new Chart(this.$refs.chartCanvasRef as HTMLCanvasElement, {
        type: this.type,
        data: {
          datasets: []
        },
        plugins: [PrometheusPlugin],
        options: {
          layout: {
            padding: 10
          },
          elements: {
            point: {
              radius: 2
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            'gsk-prometheus-plugin' : {
              backgroundColor: this.backgroundColor,
              borderColor: this.borderColor,
              fill: this.fill,
              label: this.label,
              borderWidth: this.borderWidth,
              service: this.service,
              reportType: this.reportType,
              projectId: this.projectId,
              stepped: this.stepped
            },
            'title': {
              display: false,
            },
            tooltip: {
              enabled: true
            },
            legend: {
              position: 'top',
              labels: {
                usePointStyle: this.legendUsePointStyle,
                generateLabels: chart => chart.data.datasets.map((data, i) => {
                  if(data.label){
                    return ({
                      datasetIndex: 0,
                      index: i,
                      text: this.truncateLegend && data.label?.length > 10 ? data.label?.substring(0,10)+'...' : data.label,
                      fillStyle: chart.config?.type === 'line' ? data.borderColor: data.backgroundColor,
                      strokeStyle: chart.config?.type === 'line' ? data.borderColor: data.backgroundColor,
                      hidden: false
                    })
                  }
                  return {};
                })
              }
            },
          }
    }  
      });
  }

  unmounted() {
      this.chart.destroy();
  }
}
