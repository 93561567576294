
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import invariant from 'ts-invariant';
import { Location, RawLocation, Route } from 'vue-router';
import sortBy from 'lodash/sortBy';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { Environments, mocksEnabled, RouteNames, Statuses } from '@/constants';
import ProjectEnvServices from '@/components/project-details/ProjectEnvServices.vue';
import ProjectEnvAuth from '@/components/project-details/ProjectEnvAuth.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import * as API from '@/api/projects.api';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import { ProjectEnvironmentDetails2, secretKeyValPair } from '@/types/projects.types';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import UserCircle from '@/components/UserCircle.vue';
import { UINavigationItem } from '@/types';
import NavigationList from '@/components/NavigationList.vue';
import GAnalytics from '@/components/GAnalytics';
import ProjectEnvRegistrations from '@/components/project-details/ProjectEnvRegistrations.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import NavigationTabs from '@/components/NavigationTabs.vue';
import EmptyState from '@/components/EmptyState.vue';
import { Status } from '@/types/enum.types';
import { RadioField } from '@/components/form/form.types';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

enum parts {
  AUTH = 'auth',
  SERVICES = 'services',
  REGISTRATIONS = 'registrations',
  STATUS = 'status',
  RPA = 'rpa',
}

const dataParts = [parts.AUTH, parts.SERVICES, parts.REGISTRATIONS, parts.RPA];

let timer: number;

type NavigationGuard<V extends Vue = Vue> = (
  to: Route,
  from: Route,
  next: (to?: RawLocation | false | ((vm: V) => any) | void) => void,
  isUpdate: boolean,
) => unknown;

const noop = () => {
  'noop';
};

function loadEnvData(projectId: number, projectEnvId: number, section: string, next: () => void) {
  return ProjectDetailsModule.getProjectEnvironmentPart({
    projectId,
    projectEnvId,
    partName: parts.STATUS,
  }).then(() => {
    const provisioning =
      ProjectDetailsModule.envs[projectId][projectEnvId].status.statusName ===
      Statuses.Provisioning;
    if (!provisioning) {
      const currentEnvContext = section === parts.AUTH? dataParts: dataParts.filter((item) => item !== parts.AUTH);
      const reqs = currentEnvContext.map(part =>
        ProjectDetailsModule.getProjectEnvironmentPart({
          projectId,
          projectEnvId,
          partName: part,
        }),
      );
      next();
      return Promise.allSettled(reqs);
    }
    next();
  });
}

const beforeRoute: NavigationGuard = (to, from, next, isUpdate): void => {
  const projectId = Number(to.params.id);
  const projectEnvId = Number(to.params.env);
  const section = to.params.section as parts;
  // default to auth section if user nagivates to no section
  if (!dataParts.includes(section)) {
    to.params.section = parts.AUTH;
    return next({ ...(to as unknown as Location), replace: true });
  }

  // switching between sections
  if (isUpdate && to.params.env === from.params.env) {
    ProjectDetailsModule.getProjectEnvironmentPart({
      projectId,
      projectEnvId,
      partName: section,
    });
    return next();
  }
  // not switching between sections, just arriving to project env
  loadEnvData(projectId, projectEnvId, section, next);
};

@Component({
  components: {
    ProjectEnvRegistrations,
    GButton,
    NavigationList,
    ProjectEnvServices,
    ProjectEnvAuth,
    DeleteDialog,
    UserCircle,
    GAnalytics,
    NavigationTabs,
    EmptyState,
    ValidatedFormDialog,
    GDialog
  },
  beforeRouteEnter(to, from, next) {
    beforeRoute(to, from, next, false);
  },
  beforeRouteUpdate(to, from, next) {
    beforeRoute(to, from, next, true);
  },
})
export default class ProjectEnvView extends Vue {
  @Prop({ required: true, type: String }) readonly env!: string;
  @Prop({ required: true, type: String }) readonly section!: string;
  @Prop({ required: true, type: String }) readonly id!: string;

  private promoting = false;
  private promoteToProdNotify = false;
  private promoteToQaNotify = false;
  private pollDelay: number = mocksEnabled ? 1000 : 10 * 1000;
  public openUnavailableDialog = false;
  sleep(): Promise<void> {
    return new Promise(resolve => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(resolve, this.pollDelay);
    });
  }

  get tabs(): UINavigationItem[] {
    return sortBy(this.environments, ['environmentId']).map(
      (env): UINavigationItem => ({
        text: env.environmentName,
        key: env.environmentName,
        replace: true,
        route: {
          name: RouteNames.ProjectEnvDetails,
          params: {
            ...this.$route.params,
            env: env.projectEnvironmentId.toString(),
          },
        },
        disabled: env.projectEnvironmentId === -1,
      }),
    );
  }

  get noEnv() {
    return this.environments.filter(env => env.projectEnvironmentId !== -1).length === 0;
  }

  oauthTypeForm: [RadioField] = [
    {
      key: 'oauthType',
      type: 'radio',
      value: FeatureFlagsModule.pingEnabled ? 'PING_OAUTH' : 'AD_OAUTH',
      label: 'OAuth Provider',
      options: [
        {
          label: 'Ping Federate',
          value: 'PING_OAUTH',
        },
        {
          label: 'Azure Active Directory',
          value: 'AD_OAUTH',
        },
      ],
    },
  ];

  oauthTypeOpen = false;
  oauthLoading = false;

  get hasOauthOptions(): boolean {
    return FeatureFlagsModule.azureADEnabled && FeatureFlagsModule.pingEnabled;
  }
  get maintenanceModeDisabledDev(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledDev;
  }
  get maintenanceModeDisabledQA(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledQA;
  }
  get maintenanceModeDisabledProd(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledProd;
  }
  get maintenanceModeMessage() {
    return ProcessEnvsModule.processEnvs.maintenanceModeMessage;
  }

  showUnavailableDialog(): void {
    this.openUnavailableDialog = true;
  }

  async submitApiEnv() {
    const val = this.oauthTypeForm[0].value;
    invariant('PING_OAUTH' === val || 'AD_OAUTH' === val, 'invalid oauth type');
    this.oauthLoading = true;
    try {
      await API.addApiEnvToProject(this.projectId, val);
    } catch (e) {
      openErrorSnackbar.call(this, e.message);
      return;
    } finally {
      this.oauthLoading = false;
    }
    await ProjectDetailsModule.baseGetProject(this.projectId);
    const penv = ProjectDetailsModule.devEnv?.projectEnvironmentId;
    if (penv) {
      this.$router.replace({
        name: RouteNames.ProjectEnvDetails,
        params: { id: this.projectId + '', env: penv + '' },
      });
    }
  }

  async addApiEnv() {
    if (this.hasOauthOptions) {
      this.oauthTypeOpen = true;
    } else {
      this.submitApiEnv();
    }
  }

  get promoteAnalytics(): ClickData {
    return {
      clickTarget: `project-env-promote-button:${this.nextEnv.name}`,
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
      promoteTo: this.nextEnv.name,
    };
  }

  get projectId(): number {
    return Number(this.id);
  }

  get projectEnvId(): number {
    return Number(this.env);
  }

  getSectionRoute(section: string): UINavigationItem['route'] {
    return {
      ...(this.$route as unknown as Location),
      params: {
        ...this.$route.params,
        section,
      },
    };
  }

  get envStatus() {
    return this.projectEnv.status;
  }

  get sectionLinks(): UINavigationItem[] {
    const links: UINavigationItem[] = [
      {
        key: parts.AUTH,
        route: this.getSectionRoute(parts.AUTH),
        exact: true,
        text: 'Authentication',
        props: {
          noripple: true,
        },
      },
      {
        key: parts.SERVICES,
        exact: true,
        route: this.getSectionRoute(parts.SERVICES),
        text: 'Connected Services',
      },
    ];
    if (FeatureFlagsModule.registrationEnabled) {
      links.push({
        key: parts.REGISTRATIONS,
        exact: true,
        route: this.getSectionRoute('registrations'),
        text: 'API Registrations',
      });
    }
    if (FeatureFlagsModule.botPromotionEnabled) {
      links.push({
        key: parts.RPA,
        exact: true,
        route: this.getSectionRoute('rpa'),
        text: 'RPA',
      });
    }
    return links;
  }

  get promoteDisabled() {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    return (
      !this.projectEnv.status.canPromote || EnumsModule.enums.role.PROJECTVIEWER.id === currentUserRoleId
    );
  }

  get provisioning() {
    return this.projectEnv.status.statusName === Statuses.Provisioning;
  }

  get projectEnv(): ProjectEnvironmentDetails2 {
    return ProjectDetailsModule.envs[this.projectId][this.projectEnvId];
  }

  get authDetails() {
    return this.projectEnv.auth;
  }

  get connectedServices() {
    return this.projectEnv.services;
  }

  get registrationServices() {
    return this.projectEnv.registrations;
  }

  reload(): Promise<unknown> {
    return loadEnvData(this.projectId, this.projectEnvId, this.section, noop);
  }

  fetchStatus(): Promise<void> {
    return ProjectDetailsModule.getProjectEnvironmentPart({
      projectId: this.projectId,
      projectEnvId: this.projectEnvId,
      partName: parts.STATUS,
    });
  }

  handleDelete(): void {
    // only reload status when not polling because the polling process
    // would already be doing the same thing
    if (!this.polling) {
      this.fetchStatus();
    }
  }

  pollComplete = Promise.resolve();
  polling = false;
  destroyed = false;
  @Watch('anyProvisioning', { immediate: true })
  async pollWatcher(): Promise<void> {
        
    // using this promise acts as a queue and ensures any subsequent watcher
    // calls don't run while previous calls are still busy
    this.pollComplete = this.pollComplete.then(async () => {
      if (this.destroyed) {
        this.polling = false;
        return;
      }
      this.polling = true;
      while (this.anyProvisioning) {
        if (this.destroyed) {
          this.polling = false;
          return;
        }
        await this.reload();
        await this.sleep();
      }

      if (this.destroyed) {
        this.polling = false;
        return;
      }
      // status should be the last thing called so that "canPromote" doesn't get
      // out of sync
      await this.fetchStatus();
      this.polling = false;
    });
  }

  beforeDestroy() {
    this.destroyed = true;
  }

  get anyProvisioning(): boolean {
    return [
      this.provisioning,
      this.connectedServicesProvisioning,
      this.registrationsProvisioning,
    ].some(p => p);
  }

  get connectedServicesProvisioning(): boolean {
    return this.connectedServices.connectedServices.some(serv =>
      [Status.Provisioning, Status.Deleting, Status.Approved].includes(serv.status as Status),
    );
  }

  get registrationsProvisioning(): boolean {
    return this.registrationServices.services.some(regs =>
      regs.some(reg => [Status.Provisioning, Status.Deleting, Status.Approved, Status.Scanned, Status.Scanning].includes(reg.status as Status)),
    );
  }

  get environments(): DeepReadonly<API.Projects.ProjectEnvironment[]> {
    return (ProjectDetailsModule.projectDetails.environments || []).filter(
      env => env.environmentTypeMnemonic === 'API',
    );
  }

  get currentEnvironment() {
    return this.environments.find(
      env => env.projectEnvironmentId.toString() === this.$route.params.env,
    );
  }

  get currentEnvironmentName(): Environments | '' {
    if (this.currentEnvironment) {
      return this.currentEnvironment.environmentName as Environments;
    }
    return '';
  }

  get nextEnv() {
    const currentEnv = this.currentEnvironment;
    let nextEnv: Environments | undefined;

    if (currentEnv) {
      switch (currentEnv.environmentName) {
        case Environments.Dev:
          nextEnv = Environments.Qa;
          break;
        case Environments.Qa:
          nextEnv = Environments.Prod;
          break;
        case Environments.Prod:
          break;
        default:
          break;
      }
    }

    if (nextEnv) {
      return {
        name: nextEnv,
        disabled: false,
        hide: false,
      };
    }

    return {
      name: '',
      disabled: true,
      hide: true,
    };
  }

  prePromote(sendClick: () => void) {
    const notificationEnabled = FeatureFlagsModule.promoteToProdNotify;
    const promoteToQaNotificationEnabled = FeatureFlagsModule.promoteToQaNotify;
    const hasPromotableRegs = !!this.projectEnv.registrations.services
    .flat()
    .filter(s => s.canPromote).length;
    if(notificationEnabled && this.nextEnv.name === Environments.Prod && hasPromotableRegs) {
      this.promoteToProdNotify = true;
    } else if(promoteToQaNotificationEnabled && this.nextEnv.name === Environments.Qa && hasPromotableRegs) {
      this.promoteToQaNotify = true;
    } else {
      this.promote(sendClick)
    }
  }
  
  promote(sendClick: () => void) {
    if (this.promoting) {
      return;
    }
    if(this.maintenanceModeDisabledQA || this.maintenanceModeDisabledProd){
      this.showUnavailableDialog()
      return;
    }
    sendClick();
    const regEnabled = FeatureFlagsModule.registrationEnabled;
    const dynamicRegEnabled = FeatureFlagsModule.dynamicRegistrationEnabled;
    const hasPromotableRegs = !!this.projectEnv.registrations.services
      .flat()
      .filter(s => s.canPromote).length;
    // if has registrations
    if ((hasPromotableRegs && regEnabled) || this.nextEnv.name === Environments.Prod) {
      return this.$router.push({
        name: dynamicRegEnabled? RouteNames.ProjectPromoteNew: RouteNames.ProjectPromote,
        params: {
          id: this.$route.params.id,
          env: this.$route.params.env,
        },
      });
    } else if (this.nextEnv.name === Environments.Qa) {
      this.promoting = true;
      ProjectDetailsModule.promoteEnvironment({
        projectEnvId: this.$route.params.env,
        srcEnv: Environments.Dev,
        services: [],
      })
        .then(res => {
          this.$router.push({
            name: RouteNames.ProjectEnvDetails,
            params: addAnalyticsRouteParams(
              {
                id: this.$route.params.id,
                env: res.projectEnvironmentId.toString(),
                section: parts.AUTH,
              },
              {
                projectName: ProjectDetailsModule.projectDetails.projectName,
                projectId: ProjectDetailsModule.projectDetails.projectId,
              },
            ),
          });
        })
        .catch(() => {
          openSnackbar.call(this, 'Server Error: Promotion request failed');
        })
        .finally(() => {
          this.promoting = false;
        });
    }
  }

  reloadRegs() {
    ProjectDetailsModule.getProjectEnvironmentPart({
      projectId: this.projectId,
      projectEnvId: this.projectEnvId,
      partName: parts.REGISTRATIONS,
    });
  }

  async addClientSecret(payload: { envId: number; ssoSettingId: string; description: string }) {
    ProjectDetailsModule.setAuthDetailsLoading(true);
    await ProjectDetailsModule.createNewClientSecret(payload);
    await this.reload();
    ProjectDetailsModule.setAuthDetailsLoading(false);
  }

  async addApiKey(payload: { envId: number; ssoSettingId: string; name: string }) {
    ProjectDetailsModule.setApiKeyAddLoading(true);
    await ProjectDetailsModule.createNewApiKey(payload);
    await this.reload();
    ProjectDetailsModule.setApiKeyAddLoading(false);
  }

  async deleteClientSecret(payload: {
    envId: number;
    ssoSettingId: string;
    clientSecretId: string;
  }) {
    ProjectDetailsModule.setClientSecretDeleteLoading(true);
    await ProjectDetailsModule.deleteClientSecret(payload);
    await this.reload();
    ProjectDetailsModule.setClientSecretDeleteLoading(false);
  }

  async deleteApiKey(payload: {
    envId: number;
    apiKeyId: string;
  }) {
    ProjectDetailsModule.setApiKeyDeleteLoading(true);
    await ProjectDetailsModule.deleteApiKey(payload);
    await this.reload();
    ProjectDetailsModule.setApiKeyDeleteLoading(false);
  }

  async resetClientSecret(payload: { envId: number; ssoSettingId: string }) {
    ProjectDetailsModule.setClientSecretResetLoading(true);
    await ProjectDetailsModule.resetClientSecret(payload);
    await this.reload();
    ProjectDetailsModule.setClientSecretResetLoading(false);
  }

  async modifySecretCredential(payload: {envId: number, clientId: string, secrets: string | secretKeyValPair[]}) {
    ProjectDetailsModule.setClientSecretSavingLoading(true);
    try {
      await ProjectDetailsModule.modifySecretCredential(payload);
      await this.reload();
    } finally {
      ProjectDetailsModule.setClientSecretSavingLoading(false);
    }
    
  }
}
