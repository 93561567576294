
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as API from '@/api/projects.api';
import CopyCode from '@/components/CopyCode.vue';
import UserList from '@/components/UserList.vue';
import UserCircle from '@/components/UserCircle.vue';
import { get } from 'lodash';

@Component({
  components: {
    CopyCode,
    UserList,
    UserCircle,
  },
})
export default class ProjectEnvApprovers extends Vue {
  @Prop({ type: String, required: true }) serviceNowId!: string;
  @Prop({ type: Number, required: true }) serviceId!: number;
  @Prop(Boolean) approvalTypeHeading!: boolean;
  @Prop({ type: String, default: '' }) approvalTypeText!: string;
  @Prop({ type: String, default: '' }) approversTooltipText!: string;
  public isLoading = false;
  public usersList = [];
  public initialApprovalTypeText = this.approvalTypeText;
  public initialApproversTooltipText = '';
  async mounted() {
    const MAX_RETRY_COUNT = 5;
    const RETRY_INTERVAL = 10000;
    const approverListData = await this.retryFetchServiceApproverList(
      MAX_RETRY_COUNT,
      RETRY_INTERVAL,
    );
    if (approverListData) {
      this.initialApproversTooltipText = approverListData?.data
        .map((el, idx, array) => {
          this.usersList.push(el);
          return `${el.fullName} (${el.mudId}) ${idx === array.length - 1 ? '' : ', '}`;
        })
        .join('');

      const isCiOwner =
        this.usersList.find((user: any) => user.mnemonic === 'APISYSTEMADMINAPPROVAL') !==
        undefined;

      this.initialApprovalTypeText = isCiOwner
        ? 'CI Owner Approval'
        : get(this.usersList, '[0].name', '');
      this.$emit('update:approvalTypeText', this.initialApprovalTypeText);
      this.$emit('update:approversTooltipText',this.initialApproversTooltipText);
    }
    this.isLoading = true;
  }
  
  async retryFetchServiceApproverList(maxRetries: number, retryInterval: number) {
    let retries = 0;
    while (retries <= maxRetries) {
      const approverListData = await this.fetchServiceApproverList();
      if (approverListData?.data?.length > 0) return approverListData;
      if (retries < maxRetries) {
        await new Promise(resolve => setTimeout(resolve, retryInterval));
      }
      retries++;
    }
    return null;
  }
  async fetchServiceApproverList() {
    try {
      return await API.getServiceApproverList(this.serviceId);
    } catch {
      return null;
    }
  }

}
