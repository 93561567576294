
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { EnumsModule } from '@/store/modules/enums.module';
import dayjs from 'dayjs';
import  customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)




@Component({})
export default class ApiKeysSecretsTable extends Vue {
    @Prop({type: Array}) readonly rows!: any[];
    @Prop({type: String, default: ''}) readonly valuesType!: string;

    get canUserEditProject(): boolean {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    let isOwner = EnumsModule.enums.role.OWNER.id === currentUserRoleId;
    return !isOwner;
  }

  formattedDate(dateString: string) {
    let date;

    if (dateString.length > 10) {
        date = dayjs(dateString).format("DD-MM-YYYY");
    } else {
        date = dayjs(dateString, ["DD-MM-YYYY", ""]).format("DD-MM-YYYY");
    }
    return date
  }

  showValue(id: string) {
    this.$emit('showValue', id, this.valuesType)
  }

  showDeleteDialog(id: string) {
    this.$emit('showDeleteDialog', id, this.valuesType)
  }

}
