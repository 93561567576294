import axios from '@/api/service';
import { Chart } from "chart.js";
import { PrometheusReportType, REPORT_TYPE_BACKEND_URL } from "./prometheus-report-type.enum";

class PrometheusPluginOptions {
  service: string | undefined;
  query: string | undefined;
  duration: string | undefined;
  step: number | undefined;
  fill?: boolean = false;
  borderWidth?: number = 2;
  borderColor?: {pattern: string, color: string}[] = [];
  backgroundColor?: {pattern: string, color: string}[] = [];
  label?: string;
  reportType?: PrometheusReportType;
  projectId?: number;
  stepped?:boolean;
}

class PrometheusPluginInternals {
  loading?: boolean ;
  rendering?: boolean;
  start?: number;
  end?: number;
  step?: number;
  error?: string;
}

declare module 'chart.js' {
  interface PluginOptionsByType<TType extends ChartType> {
    'gsk-prometheus-plugin'?: {
      query?: string,
      duration?: string,
      backgroundColor?: {pattern: string, color: string}[],
      borderColor?: {pattern: string, color: string}[],
      fill?:boolean,
      label?: string,
      borderWidth?:number,
      service?: string,
      projectId?: number,
      reportType?: string,
      environment?: string;
      stepped?:boolean;
    }
  }
}


class PrometheusPlugin {
    id = 'gsk-prometheus-plugin';
    prometheusInternals: PrometheusPluginInternals | undefined;
    constructor() {}
  
    public beforeInit(chart: Chart, args: any, _options: any) {
      this.prometheusInternals = new PrometheusPluginInternals();
    }

    public beforeUpdate(chart: Chart, args: any, _options: any) {
      if(this.prometheusInternals && (this.prometheusInternals.loading === true || this.prometheusInternals.rendering === true)) {
        return;
      }
      const prometheusPluginOptions = Object.assign(new PrometheusPluginOptions(), _options);
      const q = prometheusPluginOptions.query;
      this.updateMessage(chart, _options);
      if(!REPORT_TYPE_BACKEND_URL[prometheusPluginOptions.reportType]) {
        prometheusPluginOptions.error = 'Invalid analytics report';
        chart.data.datasets = [];
        this.setTimeAxesOptions(chart);
        this.resumeRendering(chart);
      }
      axios.post(REPORT_TYPE_BACKEND_URL[prometheusPluginOptions.reportType], {
        projectId: prometheusPluginOptions.projectId, 
        environment: prometheusPluginOptions.environment,
        service: prometheusPluginOptions.service, 
        chartWidth: (chart.width || 600)})
        .then(resp => resp.data)
        .then((series) => {
          const data = series.map((serie: Record<string, unknown>, i: number) => {
            const label = (serie['labels'] as Record<string, string>)[prometheusPluginOptions.label];
            let borderColor;
            if(prometheusPluginOptions.borderColor) {
              borderColor = prometheusPluginOptions.borderColor.find( ({pattern}: {pattern: string}) => {
                return (pattern === label || (new RegExp(pattern)).test(label))
              })?.color;
            }  
            if(!borderColor) {
              borderColor = this.getUniqueColor(i);
            }
            const backgroundColor = prometheusPluginOptions.backgroundColor && prometheusPluginOptions.backgroundColor.find( ({pattern}: {pattern: string}) => {
              return (pattern === label || (new RegExp(pattern)).test(label))
            })?.color || '#fff';

            return {
              tension: 0.4,
              cubicInterpolationMode: 'default',
              stepped:prometheusPluginOptions.stepped || false,
              fill: prometheusPluginOptions.fill || false,
              label: label,
              backgroundColor,
              borderColor,
              borderWidth: prometheusPluginOptions.borderWidth,
              data: serie.data
            }

          })
          chart.data.datasets = [...data];
          if(chart.data.datasets.length > 0) {
            this.setTimeAxesOptions(chart);
          }
          this.resumeRendering(chart);
        }).catch(error => {
          prometheusPluginOptions.error = error?.message || 'Failed to fetch data';
          chart.data.datasets = [];
          this.setTimeAxesOptions(chart);
          this.resumeRendering(chart);
        });
        return false;
    }  
    
    public afterDraw(chart: Chart, args: any, _options: any) {
      this.updateMessage(chart, _options);
  }
    

    private setTimeAxesOptions(chart: Chart) {
      const plugin = chart.config.plugins?.find(plugin => plugin.id === this.id);
      const options = chart.config.options
      
      Object.assign(chart.config.options?.scales || {}, {
        x: {
          beginAtZero: true,
          type: 'timeseries',
          grid: {
            display: true,
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            stepSize: 1,
            display: true,
            major: {
                enabled: true
            },
         },
          time: {
              minUnit: 'hour',
              displayFormats: {hour: 'HH:mm'}
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
          },
          
        } 
       
      })
    }

    private resumeRendering(chart: Chart) {
      this.prometheusInternals!.loading = false;
      this.prometheusInternals!.rendering = true;
      chart.update();
      this.prometheusInternals!.rendering = false;
    }

    /**
     * 
     * @param chart 
     * @param _options 
     */
    private updateMessage(chart: Chart, _options: any) {
      const prometheusPluginOptions = Object.assign(new PrometheusPluginOptions(), _options);
      if(prometheusPluginOptions.error) {
        this.writeText(chart, prometheusPluginOptions.error, (ctx) => {
          ctx.direction = 'ltr';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "16px normal 'Helvetica Nueue'";
        });
      } else if (prometheusPluginOptions.loading) {
        this.writeText(chart, 'Loading data...', (ctx) => {
          ctx.direction = 'ltr';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "16px normal 'Helvetica Nueue'";
        });
      } else if(chart.data.datasets.length === 0 || chart.data.datasets[0].data.length === 0) {
        this.writeText(chart, 'No data to display', (ctx) => {
          ctx.direction = 'ltr';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "16px normal 'Helvetica Nueue'";
        });
      }

    }

    private writeText(chart: Chart, message: string, fn?: (ctx: CanvasRenderingContext2D) => void) {
      const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        chart.clear();

        ctx.save();
        if (fn) {
            fn(ctx);
        }

        ctx.fillText(message, width / 2, height / 2);
        ctx.restore();
    }

    public destroy(chart: Chart) {
      chart.destroy()
    }

    private getUniqueColor(n: number) {
      const rgb = [0, 0, 0];
    
    for (let i = 0; i < 24; i++) {
      rgb[i%3] <<= 1;
      rgb[i%3] |= n & 0x01;
      n >>= 1;
    }
    
    return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '')
  }
}

export default new PrometheusPlugin();