
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { mixins } from 'vue-class-component';
import { Location } from 'vue-router';
import { Environments, RouteNames, AUTH_SRC, LlmIgnoreChars } from '@/constants';
import * as API from '@/api/projects.api';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { PublishingModule } from '@/store/modules/publishing.module';
import ContentCard from '@/components/ContentCard.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import { ClickData } from '@/analytics';
import ProjectEnvMetaMixin from '@/components/mixins/project-env-meta.mixin';
import GAnalytics from '@/components/GAnalytics';
import IconMenu from '@/components/gsk-components/menu/IconMenu.vue';
import { MenuAction, MenuOption } from '@/components/gsk-components/menu/menu.types';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import { openErrorSnackbar, openSnackbar } from '@/utils/components';
import { removeAPIRegistration } from '@/api/registration.api';
import { removeQuery } from '@/utils/routing';
import FeatureFlag from '@/components/FeatureFlag';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';
import { EnumsModule } from "@/store/modules/enums.module";
import ProjectEnvTooltip from './ProjectEnvTooltip.vue';
import CopyCode from '@/components/CopyCode.vue';
import ProjectEnvApprovers from './ProjectEnvApprovers.vue';
import StatusIconComponent from '@/components/StatusIconComponent.vue';
import { Platform, Status } from '@/types/enum.types';
import GPrometheusAnalyticsChart from '@/components/gsk-components/prometheus/GPrometheusAnalyticsChart.vue'
import GPopover from '../gsk-components/popover/GskPopover.vue';
import { PrometheusReportType } from '../gsk-components/prometheus/prometheus-report-type.enum';
import { ProjectService, ProjectServiceProperties } from '@/types/projects.types';

@Component({
  components: {
    ContentCard,
    GButton,
    DeleteDialog,
    GDialog,
    GAnalytics,
    IconMenu,
    ValidatedFormDialog,
    FeatureFlag,
    ProjectEnvTooltip,
    CopyCode,
    ProjectEnvApprovers,
    StatusIconComponent,
    GPrometheusAnalyticsChart
  },
})
export default class ProjectEnvRegistrations extends mixins(ProjectEnvMetaMixin) {
  @Prop({ type: Array, required: true })
  connectedServices!: DeepReadonly<API.Projects.ProjectService[][]>;
  @Prop({ type: String, required: true })
  environmentName!: Environments;
  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ type: Number, required: true }) projectEnvId!: number;
  @Prop({ type: String, default: Platform.AZURE }) platformName!: string;
  public toggles : any= {}
  public openStatsView = false;
  public selectedServiceForStats: API.Projects.ProjectService | null = null ;
  public approvalTypeText = '';
  public approversTooltipText = '';

  get showRegisterButton(): boolean {
    return this.environmentName === Environments.Dev && this.canEditProject;
  }

  public openUnavailableDialog = false;
  public loadingTooltipData = false;

  private successOpen = false;
  private successMessageCustom = false;
  private reloadInterval = 0;
  private RELOAD_CALL_INTERVAL = 15000; 
  public approversKey = 0;

  async created() {
    const s = 'success';
    const c = 'isCustom';
    if (s in this.$route.query) {
      this.successOpen = true;
      this.successMessageCustom = this.$route.query?.[c] === '1';
      removeQuery(this, [s, c]);
    }
    this.reloadInterval = setInterval(
      this.retryPendingApprovalApi,
      this.RELOAD_CALL_INTERVAL,
    );
  }

  async retryPendingApprovalApi() {
    const { projectEnvId, projectId } = this;
    const registration = ProjectDetailsModule.envs[projectId][projectEnvId].registrations;
    const checkStatus = registration.services.some(regs =>
      regs.some(reg => [ Status.PendingApproval].includes(reg.status as Status)),
    );
    if(!checkStatus){
      clearInterval(this.reloadInterval);
    }else{
      this.approversKey += 1;
      this.$emit('reload');
    }
  }
  destroyed() {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
  }

  get isDelayedProcessingInfo(): string {
    return ProcessEnvsModule.processEnvs.delayedProcessingInfo;
  }

  private selectedService = -1;
  private deleteRegistrationOpen = false;
  private loading = false;
  public openDeleteDialog(serviceId: number): void {
    this.selectedService = serviceId;
    this.deleteRegistrationOpen = true;
  }

   downloadReport(service: API.Projects.ProjectService){
    if(!service.scanId) {
      return;
    }
    ProjectDetailsModule.downloadInvictiReport(service.scanId)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data],{ type: "application/pdf" }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', service.resourceName+' scan report.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }

  getModeForService(service: API.Projects.ProjectService) {
    return service.serviceProperties.formMode === 'advanced';
  }

  private async removeRegistration(serviceId: number) {
    this.loading = true;
    await removeAPIRegistration(serviceId)
      .then(() => {
        openSnackbar.call(this, 'Registered Service Successfully Deleted');
        this.$emit('delete');
      }).catch((err)=> {
        openErrorSnackbar.call(this, err?.response?.data?.error || 'Error: Failed to delete Registered Service');
      })
      .finally(() => {
        this.deleteRegistrationOpen = false;
        this.loading = false;
      });
    this.tryAgain();
  }

  /**
   * returns a link to one of:
   *   - publishing flow to do initial listing
   *   - publishing flow to work on existing draft
   *   - public listing
   */
  servicePubLink(
    service: API.Projects.ProjectService,
    serviceSiblingList: API.Projects.ProjectService[],
  ): {
    text: string;
    tooltip: string;
    id: string;
    provisioned: boolean;
    link: import('vue-router').Location;
  } {
    const provisioned = service.status === 'PROVISIONED';
    if (service.publishedListingId !== null) {
      let link = {
        name: RouteNames.ListingDetails,
        params: {
          listingId: service.publishedListingId.toString(),
        },
        query: {},
      };

      if (service.publishedListingVersionId) {
        link.query = { version: service.publishedListingVersionId };
      }

      return {
        provisioned,
        text: 'View',
        tooltip: 'View the published listing for this API',
        id: `view-${service.publishedListingId}`,
        link,
      };
    } else if (service.draftListingId !== null) {
      return {
        provisioned,
        text: 'Edit Draft',
        tooltip: 'Edit the work in progress draft listing of this API',
        id: `edit-${service.draftListingId}`,
        link: {
          name: RouteNames.PublishListing,
          params: {
            listingId: service.draftListingId.toString(),
            listingVersionId:
              service && service.draftListingVersionId
                ? service.draftListingVersionId.toString()
                : '',
          },
          query: {
            mode: 'edit',
          },
        },
      };
    } else {
      const previouslyPublishedListingId = this.getPreviouslyPublishedListingId(serviceSiblingList);

      if (previouslyPublishedListingId) {
        // publishing new versions
        return {
          provisioned,
          text: 'Publish',
          tooltip: 'Crate a new version of this API',
          id: `publish-${service.draftListingId}`,
          link: {
            name: RouteNames.PublishListing,
            params: {
              listingId: previouslyPublishedListingId.toString(),
            },
            query: { mode: 'version' },
          },
        };
      } else {
        // publishing the first version of the api
        return {
          provisioned,
          text: 'Publish',
          tooltip: 'Start drafting a public listing of this API so that others can use it',
          id: `publish-${service.draftListingId}`,
          link: {
            name: RouteNames.PublishListing,
            query: {
              r: service.registrationId.toString(),
              rv: service.registrationVersionId.toString(),
            },
          },
        };
      }
    }
  }

  onBeforePublishRoute() {
    PublishingModule.resetPublishingState();
  }

  getPreviouslyPublishedListingId(
    serviceSiblingList: API.Projects.ProjectService[],
  ): number | null {
    const publishedListing = serviceSiblingList.find(
      currentService =>
        currentService.status === 'PROVISIONED' && currentService.publishedListingId,
    );
    if (publishedListing) {
      return publishedListing?.publishedListingId;
    } else {
      return null;
    }
  }

  getIconMenuOptions(service: API.Projects.ProjectService, showAddVersion: boolean): MenuOption[] {
    type ServiceMenuAction = MenuAction<typeof service>;
    const out: MenuOption[] = [];
    if (service.status !== 'PROVISIONING' && service.status !== 'FAILED') {
        out.push({
            key: 'edit',
            text: 'Edit Version',
            handler: () => {
              if(this.maintenanceModeEnabled) {
                this.showUnavailableDialog();
              }
            },
            link: this.maintenanceModeEnabled ? undefined : this.editLink(service)
        });
    }

    const deleteAction: ServiceMenuAction = {
      key: 'delete',
      text: 'Delete Version',
      handler: (key, s) => {
        if(this.maintenanceModeEnabled){
          this.showUnavailableDialog();
        } else{
          this.$log('DELETE: ', s.serviceId);
          this.openDeleteDialog(s.serviceId);
        }
      },
      extra: service,
    };
    if (showAddVersion && service.status !== 'PROVISIONING' && service.status !== 'FAILED') {
      out.push({
        key: 'add-version',
        text: 'New Version',
        handler: () => {
          if(this.maintenanceModeEnabled) {
            this.showUnavailableDialog();
          }
        },
        link: this.maintenanceModeEnabled ? undefined : this.versionLink(service)
      });
      // }
    }
    if (service.status === 'PROVISIONED') {
      out.push({
        key: 'view',
        text: 'View Connected Clients',
        link: this.projectsLink(service),
      });
    }
    if(service.status !== 'DELETING'){
      out.push(deleteAction);
    }
    if (!this.apiSecurityScanFeatureEnabled && this.environmentName !== Environments.Prod && (service.status === 'PROVISIONED'  || service.status === 'INSECURE') && service.scanId) {
      out.push({
        key: 'retriggerApiScan',
        text: 'Re-run Security Scan',
        handler: (key, s) => this.triggerApiSecurityScan(s),
        extra: service
      });

      out.push({
        key: 'download-security-report',
        text: 'Download Security Report',
        handler: (key, s) => {
          this.downloadReport(service);
        },
        extra: service
      });
    }
    return out;
  }

  // map of reg id to row group open state
  rowState: Record<string, boolean | undefined> = {};
  toggleTable(serviceId: number) {
    Vue.set(this.rowState, serviceId, !this.rowState[serviceId]);
  }
  showRow(index: number, serviceId: number) {
    if (index > 0) {
      return this.rowState[serviceId];
    }
    return true;
  }
  editLink(service: API.Projects.ProjectService): Location {
    return {
      name: this.isDynamicRegistrationEnabled
        ? RouteNames.RegisterApiNewEdit
        : RouteNames.RegisterApiEdit,
      params: {
        id: '' + this.projectId,
        envId: '' + this.projectEnvId,
        serviceId: '' + service.serviceId,
      },
    };
  }
  versionLink(service: API.Projects.ProjectService): Location {
    return {
      name: this.isDynamicRegistrationEnabled
        ? RouteNames.RegisterApiNewVersion
        : RouteNames.RegisterApiVersion,
      params: {
        id: '' + this.projectId,
        envId: '' + this.projectEnvId,
        serviceId: '' + service.serviceId,
      },
    };
  }

  projectsLink(service: API.Projects.ProjectService): Location {
    return {
      name: RouteNames.ProjectConnected,
      params: {
        projectId: this.projectId.toString(),
        envId: this.projectEnvId.toString(),
        serviceId: service.serviceId.toString(),
      },
    };
  }

  getAuthType(service: API.Projects.ProjectService): any {
    const authConf = Object.keys(AUTH_SRC).find(
      item =>
        AUTH_SRC[item].servicePropEnumVal ===
        service.serviceProperties.enabled_oidc_plugin_instead_of_keyauth.toString(),
    );

    return authConf ? AUTH_SRC[authConf] : {};
  }

  get canEditProject(): boolean {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    return EnumsModule.enums.role.OWNER.id === currentUserRoleId;
  }

  get connectAnalytics(): ClickData {
    return {
      clickTarget: 'project-details-service-connect-button',
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
    };
  }

  clickAnalytics(name: string, service: API.Projects.ProjectService): ClickData {
    return {
      clickTarget: name,
      serviceName: service.resourceName,
      serviceId: service.serviceId,
      projectEnvironment: this.environmentName,
      projectName: ProjectDetailsModule.projectDetails.projectName,
      projectId: ProjectDetailsModule.projectDetails.projectId,
    };
  }

  tryAgain() {
    const { projectEnvId, projectId } = this;
    ProjectDetailsModule.getProjectEnvironmentPart({
      projectId,
      projectEnvId,
      partName: 'registrations',
    });
  }

  get viewRouteLink(): import('vue-router').Location {
    //TO-DO to be replaced with actual route to edit/view route
    return {
      name: RouteNames.RegisterNewApi
    }
  }

  get isDynamicRegistrationEnabled() {
    return FeatureFlagsModule.dynamicRegistrationEnabled;
  }

  get isOpenApiSpecEnabled() {
    return FeatureFlagsModule.openApiSpecEnabled;
  }

  get registrationLink(): import('vue-router').Location {
    if (this.isDynamicRegistrationEnabled) {
      return {
        name: this.isOpenApiSpecEnabled? RouteNames.RegisterApiOptions: RouteNames.RegisterNewApi,
        params: {
        },
      };
    } else {
      return {
        name: RouteNames.RegisterApi,
        params: {
          id: '' + this.projectId,
          env: '' + this.projectEnvId,
        },
      };
    }
  }

  kongProxyPath(ref: API.Projects.ProjectServiceProperties) {
    ProcessEnvsModule.setPlatform(this.platformName);
    const prefix:any = ProcessEnvsModule.getApiGatewayUrlMapping.get(this.environmentName.toUpperCase()) || '';
    const path = prefix + ref.kong_proxy_path;
    return path.replaceAll(LlmIgnoreChars.TILDE, '').replaceAll(LlmIgnoreChars.AI_PATH, '');
  }

  get hasConnectedServices() {
    return !!this.connectedServices.length;
  }

  get kongDisabled(): boolean {
    return !FeatureFlagsModule.kongEnabled;
  }

  get registrationDisabled(): boolean {
    return !FeatureFlagsModule.registrationEnabled;
  }
  get apiSecurityScanFeatureEnabled(): boolean {
    return !FeatureFlagsModule.apiSecurityScanFeatureEnabled;
  }
  get maintenanceModeDev(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledDev;
  }
  get maintenanceModeQA(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledQA;
  }
  get maintenanceModeProd(): boolean {
    return FeatureFlagsModule.maintenanceModeEnabledProd;
  }
  get maintenanceModeEnabled(): boolean {
    if((this.environmentName === Environments.Dev) && (this.maintenanceModeDev || this.maintenanceModeProd)){
      return true;
    }
    if((this.environmentName === Environments.Qa) && (this.maintenanceModeQA || this.maintenanceModeProd)){
      return true;
    }
    if((this.environmentName === Environments.Prod) && this.maintenanceModeProd){
      return true;
    }
    return false;
  }
  get maintenanceModeMessage() {
    return ProcessEnvsModule.processEnvs.maintenanceModeMessage;
  }

  showUnavailableDialog(): void {
    this.openUnavailableDialog = true;
  }

  async triggerApiSecurityScan(service: any){
    const {serviceId, projectEnvironmentId} = service;
    await ProjectDetailsModule.triggerAPISecurityScan({serviceId, projectEnvId: projectEnvironmentId});
    this.$emit('reload');
  }
  toggleData(index :any) {
    Vue.set(this.toggles, index, !this.toggles[index]);
  }

  get totalReportType() {
    return PrometheusReportType.TOTAL_24H_REQUEST;
  }
  get numberOfErrorsType() {
    return PrometheusReportType.NUMBER_OF_ERRORS;
  }
  get numberConsumersType() {
    return PrometheusReportType.NUMBER_OF_CONSUMERS;
  }

  get isPrometheusAnalyticsChartEnabled() {
    return FeatureFlagsModule.prometheusAnalyticsChartEnabled;
  }

  openStatsViewer(service: ProjectService) {
    this.openStatsView = true;
    this.$set(this, 'selectedServiceForStats', {...service});
  }
  get selectedKongServiceName() {
    return this.selectedServiceForStats?.serviceProperties?.kong_service_name
  }

  get selectedServiceName() {
    return this.selectedServiceForStats?.resourceName;
  }


}
