
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconStatus, IconStatusType } from '@/types/util.types';

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import watch_later_icon from '../assets/watch_later.svg';
// @ts-ignore
import check_circle_icon from '../assets/check_circle.svg';
// @ts-ignore
import cancel_black_icon from '../assets/cancel_black_24dp.svg';
// @ts-ignore
import cancel_icon from '../assets/cancel.svg';
// @ts-ignore
import verfied_icon from '../assets/verified.svg';
/* eslint-enable @typescript-eslint/ban-ts-comment */
  
  @Component({
  })
  export default class StatusIconComponent extends Vue {
    @Prop(String) readonly status!: IconStatusType;
    @Prop({ type: String, required: false}) readonly id!: string;

    get iconMap() {
      return {
        [IconStatus.PROVISIONED]: check_circle_icon,
        [IconStatus.PROVISIONING]: watch_later_icon,
        [IconStatus.DELETING]: watch_later_icon,
        [IconStatus.DRAFT]: check_circle_icon,
        [IconStatus.FAILED]: cancel_icon,
        [IconStatus.SCANNED]: check_circle_icon,
        [IconStatus.SCANNING]: watch_later_icon,
        [IconStatus.REJECTED]: cancel_black_icon,
        [IconStatus.PUBLISHED]: verfied_icon,
        [IconStatus.PENDINGAPPROVAL]: watch_later_icon,
        [IconStatus.INSECURE]: cancel_icon,
      };
    }

    mounted() {
      console.log(this.iconMap[IconStatus.PROVISIONED])
    }
  }
  