import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { EnvMeta } from '@/types/projects.types';

@Component
export default class ProjectEnvMetaMixin extends Vue {
  @Prop({ type: Object, required: true })
  public meta!: DeepReadonly<EnvMeta['meta']>;

  public get error() {
    return this.meta.error;
  }

  public get updating() {
    return this.meta.loading && !this.meta.initial;
  }

  public get updateError() {
    return !this.meta.initial && this.meta.error;
  }

  public get initialLoading() {
    return this.meta.loading && this.meta.initial;
  }

  public get initialError() {
    return this.meta.error && this.meta.initial;
  }
}
