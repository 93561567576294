import URLs from "@/api/service/urls";

export enum PrometheusReportType{
    TOTAL_24H_REQUEST = 'TOTAL_24H_REQUEST',
    NUMBER_OF_ERRORS = 'NUMBER_OF_ERRORS',
    NUMBER_OF_CONSUMERS = 'NUMBER_OF_CONSUMERS'
}

export const REPORT_TYPE_BACKEND_URL: Record<string, string> = {
    TOTAL_24H_REQUEST: URLs.PrometheusAnalytics_TotalRequest,
    NUMBER_OF_ERRORS: URLs.PrometheusAnalytics_NumberOfErrors,
    NUMBER_OF_CONSUMERS: URLs.PrometheusAnalytics_NumberOfConsumers
  };
  