
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import * as API from '@/api/projects.api';
  @Component({
    components: {
    },
  })
  export default class ProjectEnvTooltip extends Vue {
    @Prop({ type: String, required: true }) serviceNowId!: string;
    @Prop({ type: Number, required: true }) serviceId!: number;
    @Prop({ type: String, required: true }) placement!: string;
    @Prop({ type: String, required: true }) for!: string;
    
    public tooltipText = '';
    async mounted() {
      let cidata = {};
      const approverListData = await API.getServiceApproverList(this.serviceId);

      if(this.serviceNowId)
        cidata = await API.getCiBusinessOwner(this.serviceNowId);

      let approverList = '';
      approverListData.data.forEach(el => {
        approverList += el.fullName+' ('+el.email+'), ';
      })

      if(cidata.data){
        this.tooltipText = approverList + cidata.data.fullName+' ('+cidata.data.displayEmail+')';
      }
      else
        this.tooltipText = approverList;
    }
  }
  