
import { Component, Prop, Vue } from 'vue-property-decorator';
import { findIndex } from 'lodash';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';

export interface FieldErroMessages {
    invalidErrMsg: string
    tooLongErrMsg: string;
    tooShortErrMsg: string;
    invalidCharactersErrMsg: string;
    duplicateErrMsg: string;
    emptyAndSpacesErrMsg: string
}

@Component({
    components: { 
        GTextfield
    }   
})
export default class GskTextfieldWithPlusBtn extends Vue {
    @Prop({type: String, default: ''}) readonly label!: string
    @Prop({type: String, default: 'Value should be atleast 3 & max 100 characters'}) readonly helperText!: string
    @Prop({type: String, default: 'Add new value' }) readonly fieldPlaceholder?: string;
    @Prop({type: String, default: "" }) readonly canUserEditProject?: string;
    @Prop({type: [String, RegExp], default: () => /^([a-zA-Z0-9 _-]+)$/}) readonly validationRule!: string | RegExp;
    @Prop({type: Array}) readonly valuesToCompare!: string[];
    @Prop({type: Object}) readonly customErrorMessages?: FieldErroMessages;
    @Prop({type: String}) readonly fieldToCompareDuplicates!: string

    newValue = ''
    validFieldValue = true;

    validateValue(): boolean {
    this.validFieldValue = true;
    const textPattern = new RegExp(this.validationRule ||  /^([a-zA-Z0-9 _-]+)$/);
    const newValueTrimmed = this.newValue.trim().toLowerCase();
    const valuesToCompareLocal = this.valuesToCompare.map((i: any) => {
      const newI = {...i};
      newI[this.fieldToCompareDuplicates] = newI[this.fieldToCompareDuplicates]?.toLowerCase() || ""
      return newI;
    })
    const isDuplicateIndex = findIndex(valuesToCompareLocal, [this.fieldToCompareDuplicates, newValueTrimmed]);

    if(newValueTrimmed.length === 0 || isDuplicateIndex !== -1 || !textPattern.test(this.newValue)
    || newValueTrimmed.length <= 2 || newValueTrimmed.length > 100) {
      this.validFieldValue = false;
      return false;
    } else {
      if(textPattern.test(this.newValue)) {
        return true;
      } else {
        this.validFieldValue = false;
        return false;
      }
    }
  }

  get fieldValidationMessage(): string {
    const textPattern = new RegExp(this.validationRule ||  /^([a-zA-Z0-9 _-]+)$/);
    const newValueTrimmed = this.newValue.trim().toLowerCase();
    const valuesToCompareLocal = this.valuesToCompare.map((i: any) => {
      const newI = {...i};
      newI[this.fieldToCompareDuplicates] = newI[this.fieldToCompareDuplicates]?.toLowerCase() || ""
      return newI;
    })
    const isDuplicateIndex = findIndex(valuesToCompareLocal, [this.fieldToCompareDuplicates, newValueTrimmed]);
    if(newValueTrimmed.trim().length === 0) {
      return this.customErrorMessages?.emptyAndSpacesErrMsg || 'Value not be empty and should not contain only spaces';
    } else if(isDuplicateIndex !== -1) {
      return this.customErrorMessages?.duplicateErrMsg || 'Value not be a duplicate';
    } else if(!textPattern.test(newValueTrimmed)) {
      return this.customErrorMessages?.invalidCharactersErrMsg ||'Value should be only alphanumeric with spaces,dash/underscore and more than 2 characters';
    } else if(this.newValue.length<=2 ) {
      return this.customErrorMessages?.tooShortErrMsg || 'Value should have minimum 3 characters';
    } else if (this.newValue.length > 100) {
      return this.customErrorMessages?.tooLongErrMsg || "Value should have maximum 100 characters";
    } else {
      if(textPattern.test(newValueTrimmed)) {
        return ''
      }
      return this.customErrorMessages?.invalidErrMsg || 'Value is not valid'
    }
  }

  addNewValue(): void {
    if (this.validateValue()) {
      this.$emit("addNewValue", this.newValue);
      this.newValue = '';
    }
  }
}

